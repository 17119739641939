<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="./img/顶部背景.png" alt="" />
    </div>
    <!-- 大模块 -->
    <div class="joinUsDiv">
      <!-- <div class="biaoti">
        <img style="margin-right: 16px" src="./img/加入我们.png" alt="" />
        Qualification honor
      </div>
      <div
        style="
          font-size: 28px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.05);
          margin-top: 4px;
          margin-bottom: 33px;
        "
      >
        Qualification honor
      </div>

      <div class="gangwei" v-for="item in this.gangweiData" :key="item.id" @click="zhankai(item.id)">
        <div class="showDiv">
          <div class="">{{ item.name }}</div>
          <div
            
            class="dianji"
            :class="{ yidianji: item.isshow }"
          ></div>
        </div>

        <div style="display: flex">
          <div
            class="biaoqian"
            v-for="(itembq, index) in item.biaioqian"
            :key="index"
          >
            {{ itembq.bq }}
          </div>
        </div>

        <div v-show="item.isshow">
          <div class="gwzz">岗位职责</div>
          <div class="zhize" style="margin-top: 16px">
            <div v-for="(itemzz, index) in item.zhize" :key="index">
              {{ itemzz.zz }}
            </div>
          </div>
          <div class="gwzz">任职要求</div>
          <div class="zhize" style="margin-top: 16px">
            <div v-for="(itemyq, index) in item.yaoqiu" :key="index">
              {{ itemyq.yq }}
            </div>
          </div>
        </div>
      </div> -->

      <div class="biaoti">
        <img style="margin-right: 16px" src="./img/代理.png" alt="" />
        Agent recruitment
      </div>
      <div
        style="
          font-size: 28px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.05);
          margin-top: 4px;
          margin-bottom: 33px;
        "
      >
        Agent recruitment
      </div>
      <div class="daili">
        <div class="dlLift">
          <div class="dbtitle">
            <img src="./img/代标.png" alt="" />
            <span style="margin-left: 12px">Energy storage products</span>
          </div>
          <div class="ydnr" v-for="(item, index) in this.chuneng" :key="index">
            {{ item.cp }}
          </div>

          <div class="dbtitle" style="margin-top: 36px">
            <img src="./img/代标.png" alt="" />
            <span style="margin-left: 12px">New energy vehicle charging pile series products</span>
          </div>
          <div
            class="ydnr"
            v-for="(item, index) in this.xinnengyuan"
            :key="index"
          >
            {{ item.cp }}
          </div>
        </div>

        <div class="dlRight">
          <div class="dbtitle">
            <img src="./img/代标.png" alt="" />
            <span style="margin-left: 12px">Agency area</span>
          </div>
          <div class="rneirong">
            We are now recruiting provincial agents for Chinese Mainland and national agents for overseas regions.
          </div>
          <div class="dbtitle">
            <img src="./img/代标.png" alt="" />
            <span style="margin-left: 12px">Application process</span>
          </div>
          <div class="rneirong">
            Fill in the information＞Send to recruitment email＞Internal audit＞Business negotiation＞Become an authorized agent
          </div>

          <div class="dbtitle">
            <img src="./img/代标.png" alt="" />
            <span style="margin-left: 12px">information audition</span>
          </div>
          <div class="ydnr" v-for="(item, index) in this.shenghe" :key="index">
            
            {{ item.cp }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current: "", //选中项
      chuneng: [
        { cp: "Household energy storage series" },
        { cp: "Portable energy storage series" },
        { cp: "Communication energy storage series" },
        { cp: "Industrial and commercial energy storage series" },
        { cp: "Power storage series" },
      ],
      xinnengyuan: [
        { cp: "Ac charging pile series" },
        { cp: "Integrated DC charging pile series" },
        { cp: "Split DC charging pile series" },
      ],
      shenghe: [
        {
          cp: "Agency area：Some regions in Chinese Mainland and overseas regions (fill in the intended agency regions of your company)",
        },
        { cp: "Agent products：Series of products or a product (product details can be found in the company's official website product introduction)" },
        {
          cp: "Enterprise related information：Company name, registered capital, company address, number of employees, contact name, contact information, company website, company main business, email, business coverage area, and cooperation messages.",
        },
      ],
      gangweiData: [
        {
          id: 1,
          isshow: false,
          name: "前端开发工程师",
          biaioqian: [{ bq: "本科" }, { bq: "计算机" }],
          zhize: [
            { zz: "1、参与公司相关产品的需求分析和模板设计；" },
            {
              zz: "2、负责公司项目的前端HTML/JS/CSS代码编写，参与项目的后端开发；",
            },
            {
              zz: "3、负责研发平台的技术架构规划及设计；",
            },
            {
              zz: "4、前端效果调优，前端体验优化。",
            },
          ],
          yaoqiu: [
            { yq: "1、计算机相关专业，本科及以上学历；" },
            {
              yq: "2、精通JS、HTML、CSS，熟练掌握Vue、ES6；",
            },
            {
              yq: "3、熟悉Vue等前端框架，Element等界面库；熟悉Node.js技术及生态，熟练使用NPM/Webpack/Gulp等工具；熟悉前端的性能优化，熟悉使用Cheome/Safari/Firefox等前端调试工具；",
            },
            {
              yq: "4、熟悉软件开发过程，具有良好代码编程和文档整理习惯；",
            },
            {
              yq: "5、具有较强的学习能力、良好的文字功底、共同能力以及团队协作精神，积极的工作态度和较强的责任心。",
            },
          ],
        },
        {
          id: 2,
          isshow: false,
          name: "软件开发工程师",
          biaioqian: [{ bq: "本科" }, { bq: "计算机" }],
          zhize: [
            { zz: "1、负责公司软件产品接口的设计、开发以及维护工作;" },
            {
              zz: "2、参与项目需求分析，编写接口文档、设计文档等开发文档；",
            },
            {
              zz: "3、与项目组人员保持良好沟通，能快速理解、消化各方需求。",
            },
          ],
          yaoqiu: [
            { yq: "1、本科及以上学历，计算机相关专业;" },
            {
              yq: "2、java基础扎实，熟悉J2EE技术体系 (如:Springboot.SpringMVC等技术及原理) ，具有多线程、网络编程5socket) 经验;",
            },
            {
              yq: "3 熟悉Tomcat、Nginx等应用服务器中间件;",
            },
            {
              yq: "4、熟练掌握MySOL/ SOL Server数据库应用开发和查询优化熟悉redis、mongodb等非关系型数据库；",
            },
            {
              yq: "5、具备良好的分析问题、解决问题的能力，责任心强，具备良好的团队合作精神和承受压力的能力;",
            },
            {
              yq: "6、深入理解面向对象编程思想，具有标准化的代码编写习惯.了解常用设计模式，能独立完成底层框架者优先。",
            },
          ],
        },
        {
          id: 3,
          isshow: false,
          name: "嵌入式软件开发工程师",
          biaioqian: [
            { bq: "本科" },
            { bq: "计算机" },
            { bq: "电子信息" },
            { bq: "自动化" },
            { bq: "控制理论" },
          ],
          zhize: [
            { zz: "1、负责公司嵌入式产品的设计、开发、调试和维护;" },
            {
              zz: "2、与用户进行需求沟通，完成项目的售前售后技术支持;",
            },
            {
              zz: "3、参与解决产品在设计，生产及现场应用过程中的软件问题。",
            },
          ],
          yaoqiu: [
            {
              yq: "1、本科及以上学历，计算机、电子信息、自动化或控制理论相关专业;",
            },
            {
              yq: "2、熟练掌握C及C++,熟习linux操作系统，熟悉单片机、DSPARM软件相关开发;",
            },
            {
              yq: "3、熟悉STM32、ARM，底层SPI、IIC、RS485、USARTCAN等底层驱动;",
            },
            {
              yq: "4、熟悉各类通讯协议，TCP/IP、MODBUS等；",
            },
            {
              yq: "5、具有良好的合作精神，服从工作安排;",
            },
            {
              yq: "6、具有较好的英语听说能力，能够熟练阅读英文文献。",
            },
          ],
        },
        {
          id: 4,
          isshow: false,
          name: "硬件开发工程师",
          biaioqian: [
            { bq: "本科" },
            { bq: "计算机" },
            { bq: "电子工程" },
            { bq: "自动化" },
            { bq: "通信" },
          ],
          zhize: [
            { zz: "1、负责产品硬件设计需求分析，拟定硬件的实现架构和方案;" },
            {
              zz: "2、负责产品硬件的原理设计，器件选型，PCB设计，电路调试等具体硬件开发；",
            },
            {
              zz: "3、制定详细的产品规格书、测试大纲并组织实施和验证；",
            },
            {
              zz: "4、负责电子产品设计的质量控制;",
            },
            {
              zz: "5、完成各关键元件选型，整理BOM，负责新产品导入；",
            },
            {
              zz: "6、跟踪整个产品的设计、开发、测试、维护全过程，解决硬件相关的问题，文档编写及归档等。",
            },
          ],
          yaoqiu: [
            {
              yq: "1、本科及以上学历，通信、电子工程、自动化、计算机及其相关专业;",
            },
            {
              yq: "2、精通模拟电路、硬件开发技能，掌握所属行业的相关专业知识和业务流程;",
            },
            {
              yq: "3、熟练硬件开发常用工具软件，Pads，Autium Designer,Orcad等;",
            },
            {
              yq: "4、有良好的研发风格，文档习惯；",
            },
            {
              yq: "5、有良好的英语阅读能力，能阅读英文资料;",
            },
            {
              yq: "6、具有模拟电路、数字电路的设计和调试能力。",
            },
          ],
        },
      ],
    };
  },
  methods: {
    zhankai(id) {
      // this.current = id;
      const component = this.gangweiData.find(
        (component) => component.id === id
      );
      if (component) {
        this.current = component;
        component.isshow = !component.isshow;
      }
    },
  },
};
</script>

<style scoped>
.joinUsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f2f5f8;
}
.biaoti {
  display: flex;
  align-items: center;
  margin-top: 100px;
  font-size: 30px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
.gangwei {
  padding: 54px 64px 54px 64px;
  margin-bottom: 24px;
  width: 1314px;
  /* height: 211px; */
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  cursor: pointer;
}
/* .gangwei:hover {
  display: flex;
  width: 1314px;
  height: 211px;
  background: #ffffff;
  margin-bottom: 16px;
  box-shadow: 0px 0px 30px 1px rgba(9, 64, 253, 0.2);
} */
/* .gangwei:before {
  content: "";
  border-left: 8px solid #0940fd;
  height: 226px;
  margin-top: 54px;
} */
.showDiv {
  font-size: 36px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: space-between;
}
.dianji {
  background: url("./img/未点击.png") no-repeat;
  background-size: 100% 100%;
  width: 36px;
  height: 36px;
}
.yidianji {
  background: url("./img/点击.png") no-repeat;
  background-size: 100% 100%;
  width: 36px;
  height: 36px;
}
.biaoqian {
  margin-right: 12px;
  margin-top: 20px;
  height: 36px;
  background: rgba(9, 64, 253, 0.1);
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  border-radius: 6px 6px 6px 6px;
  color: #0940fd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.gwzz {
  font-size: 22px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.gwzz::before {
  content: "";
  display: flex;
  width: 9px;
  height: 9px;
  background: #0940fd;
  border-radius: 50%;
  margin-right: 16px;
}
.zhize {
  padding-left: 25px;
  margin-top: 16px;
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
}
.daili {
  width: 1314px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 188px;
}
.dlLift {
  width: 440px;
  height: 710px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  padding: 54px 0px 0px 42px;
}
.dlRight {
  width: 850px;
  height: 710px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  padding: 54px 42px 0px 42px;
}

.rneirong {
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding-left: 56px;
  margin-bottom: 36px;
}
.ydnr {
  font-size: 20px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding-left: 56px;
}
.ydnr::before {
  content: "";
  display: flex;
  width: 9px;
  height: 9px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  margin-right: 16px;
  padding-right: 9px;
}
.dbtitle {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  margin-left: 12px;
  line-height: initial;
}
</style>